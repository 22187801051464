import { api } from  '@converse/headless';
import { html } from "lit";

export default (o) => {
    const menuPersistentId = 'converse-persistent/menu-answered-' + o.msgId;
    const textarea = document.querySelector('converse-message-form textarea.chat-textarea');

    const tplOption = (opt) => html`<button type="button" ?disabled="${opt.disabled}" class="submit-chat-form-button" value="${opt.value}" title="${opt.label}" @click="${menuButtonClicked}">${opt.label}</button>`;

    async function menuButtonClicked (ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        // send menu response to XCC
        const chat = await api.chats.get(this.jid);
        chat.sendMessage({'body': ev.target.value});
        
        // disable buttons
        const buttons = this.querySelector('.menu-buttons-wrapper')?.getElementsByTagName('button');
        disableButtons(buttons);
        localStorage.setItem(menuPersistentId, "true");

        // enable textarea
        textarea.disabled = false;
    }

    function disableButtons(buttons) {
        const len = buttons.length;
        for (let i = 0; i < len; i++) {
            buttons.item(i).disabled = true;
        }
    }

    const answered = localStorage.getItem(menuPersistentId);
    if (answered || answered === "true") {
        // add the localStorage key back into memory
        localStorage.setItem(menuPersistentId, "true");
        // set the buttons disabled
        o.options.forEach(option => { option.disabled = true });
        //  and the textarea active
        textarea.disabled = false;
    } else {
        // set the textarea disabled
        textarea.disabled = true;
    }

    return html`<div class="menu-buttons-wrapper">${o.options?.map(option => tplOption(option))}</div>`;
}

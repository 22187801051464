/**
 * @typedef {import('../../../plugins/chatview/message-form.js').default} MessageForm
 */

import { __ } from 'i18n';
import { html } from 'lit';
import { until } from 'lit/directives/until.js';


function sendButtonClicked (ev) {
    ev?.preventDefault?.();
    ev?.stopPropagation?.();
    const form = /** @type {MessageForm} */(document.querySelector('converse-message-form'));
    form?.onFormSubmitted(ev);
}

function tplSendButton () {
    const i18n_send_message = __('Send the message');
    return html`<button type="submit" class="btn send-button" @click="${sendButtonClicked}" title="${ i18n_send_message }"><converse-icon color="var(--toolbar-btn-text-color)" class="fa fa-paper-plane" size="1em"></converse-icon></button>`
}

export default (el) => {
    return html`<span class="btn-group toolbar-buttons">${until(el.getButtons(), '')}</span> ${ el.show_send_button ? tplSendButton() : '' }`;
}
